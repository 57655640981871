
import {
  DepartmentTypes,
  UserDepartment,
} from "@/store/modules/department/department.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const departmentX = namespace("Department");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddDepartment: () => import("@/components/department/AddDepartment.vue"),
    EditDepartment: () => import("@/components/department/EditDepartment.vue"),
    DeleteDepartment: () =>
      import("@/components/department/DeleteDepartment.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class DepartmentsView extends Vue {
  @departmentX.Mutation(DepartmentTypes.SET_ADD_DEPARTMENT_DIALOG)
  public setAddDepartment!: (addDepartment: boolean) => void;

  @departmentX.Mutation(DepartmentTypes.SET_UPDATE_DEPARTMENT_DIALOG)
  public setUpdateDepartment!: (updateDepartment: boolean) => void;

  @departmentX.Mutation(DepartmentTypes.SET_DELETE_DEPARTMENT_DIALOG)
  public setDeleteDepartment!: (deleteDepartment: boolean) => void;

  @departmentX.Mutation(DepartmentTypes.SET_TICKET_DEPARTMENT_DATA)
  public setUserDepartmentData!: (ticketDepartment: UserDepartment) => void;

  @departmentX.Action(DepartmentTypes.LOAD_DEPARTMENTS)
  public getTicketDepartments!: (pagination: Pagination) => void;

  @departmentX.State(DepartmentTypes.DEPARTMENTS)
  public ticketDepartments!: UserDepartment[];

  @departmentX.State(DepartmentTypes.LOADING_DEPARTMENTS_STATE)
  public loadingDepartments!: boolean;

  @departmentX.State("addDepartmentRef")
  public addDepartmentRef!: number;

  openAddDepartmentDialog(): void {
    this.setAddDepartment(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "DESCRIPTION",
      value: "description",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deleteDepartment(item: UserDepartment): void {
    this.setDeleteDepartment(true);
    this.setUserDepartmentData(item);
  }

  editDepartment(item: UserDepartment): void {
    this.setUpdateDepartment(true);
    this.setUserDepartmentData(item);
  }

  mounted(): void {
    this.getTicketDepartments({ page: 1, limit: 10 });
  }
}
